import { ApiResponse } from "../../types/common";
import { IPrograms, IProgramsApi, IProgramsFilter } from "../../types/programs";
import { Api } from "../api";

class ProgramsApi extends Api {
  async getPaging(filter: IProgramsFilter) {
    return await this.execute<ApiResponse<IProgramsApi>>(
      "post",
      "program/paging",
      filter,
      null,
      undefined
    );
  }

  async getById(id: string) {
    return await this.execute<ApiResponse<IPrograms>>(
      "get",
      `program/get-by-id/${id}`,
      null,
      null,
      undefined
    );
  }
}

export default ProgramsApi;
