import api from "../../../../../common/api";

export async function documentsLoader() {
  try {
    const response = await api.account.getDocuments();
    return response;
  } catch (err) {
    console.log(err);
  }
}
