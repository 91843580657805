import { ApiResponse } from "../../types/common";
import { Api } from "../api";

export class OtpApi extends Api {
  async sendOtp(payload: { phone_number: string }) {
    return await this.execute<ApiResponse<string>>(
      "post",
      "otp/send",
      payload,
      null,
      undefined
    );
  }

  async checkOtp(payload: { _id: string; code: number }) {
    return await this.execute<string>(
      "post",
      "otp/check",
      payload,
      null,
      undefined
    );
  }
}
