import React from "react";
import { SignupContextProvider } from "../services/signupContext";
import Signup from "./Signup";

const index = () => {
  return (
    <SignupContextProvider>
      <Signup />
    </SignupContextProvider>
  );
};

export default index;
