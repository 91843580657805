import { IContract } from "common/types/contract";
import { ApiResponse, ICity } from "../../types/common";
import { Api } from "../api";

class ContractApi extends Api {
  async createContract(payload: { application_id: string; content: string }) {
    return await this.execute<ApiResponse<IContract>>(
      "post",
      "contract/create",
      payload,
      null,
      undefined
    );
  }
}

export default ContractApi;
