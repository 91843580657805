import { ApiResponse } from "../../types/common";
import {
  IUniversities,
  IUniversitiesApi,
  IUniversitiesFilter,
} from "../../types/universities";
import { Api } from "../api";

class UniversitiesApi extends Api {
  async getPaging(filter: IUniversitiesFilter) {
    return await this.execute<ApiResponse<IUniversitiesApi>>(
      "post",
      "university/paging",
      filter,
      null,
      undefined
    );
  }

  async getChoose(filter: IUniversitiesFilter) {
    return await this.execute<ApiResponse<IUniversitiesApi>>(
      "post",
      "university/choose",
      filter,
      null,
      undefined
    );
  }

  async getById(id: string) {
    return await this.execute<ApiResponse<IUniversities>>(
      "get",
      `university/get-by-id/${id}`,
      null,
      null,
      undefined
    );
  }
}

export default UniversitiesApi;
