import { Dropdown, Grid, Menu, Space } from "antd";
import { RU, US, UZ } from "country-flag-icons/react/3x2";
import { useTranslation } from "react-i18next";
import Button from "../button/Button";
import { StyledMenuItem } from "./LanguageSwitcher.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

const { useBreakpoint } = Grid;

const languages = [
  {
    label: "O'zbek",
    value: "uz",
    flag: (
      <UZ
        style={{
          width: "30px",
          borderRadius: "5px",
          display: "block",
        }}
      />
    ),
  },
  {
    label: "Русский",
    value: "ru",
    flag: (
      <RU
        style={{
          width: "30px",
          borderRadius: "5px",
          display: "block",
        }}
      />
    ),
  },
  {
    label: "English",
    value: "en",
    flag: (
      <US
        style={{
          width: "30px",
          borderRadius: "5px",
          display: "block",
        }}
      />
    ),
  },
];

const LanguageSwitcher = () => {
  const screens = useBreakpoint();
  const { i18n } = useTranslation();
  const currentLanguage = languages.find(
    (item) => item.value === i18n.language
  );

  const handleClick = (e: any) => {
    i18n.changeLanguage(e);
    localStorage.setItem("language", e);
  };

  const languageMenu = (
    <Menu>
      {languages.map((lang) => (
        <StyledMenuItem
          key={lang.value}
          onClick={() => handleClick(lang.value)}
        >
          <Space align="center">
            {lang.flag}
            {lang.label}
          </Space>
        </StyledMenuItem>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={languageMenu} trigger={["hover"]}>
      <Button
        type="text"
        shape={screens.md ? "round" : "circle"}
        size="large"
        icon={<FontAwesomeIcon icon={faGlobe} />}
      >
        {screens.md && currentLanguage?.label}
      </Button>
    </Dropdown>
  );
};

export default LanguageSwitcher;
