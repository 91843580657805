import { useNavigate } from "react-router-dom";
import api from "../../../../../common/api";

export async function personalInfoLoader() {
  try {
    const response = await api.account.getByIdPersonalInfo();
    return response;
  } catch (err) {
    console.log(err);
  }
}
