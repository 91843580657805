import { IContactForm } from "routes/home/services/homeContext";
import { ApiResponse, ICity } from "../../types/common";
import { Api } from "../api";

export class CommonApi extends Api {
  async cityGetAll() {
    return await this.execute<ApiResponse<ICity[]>>(
      "get",
      "city/get-all",
      null,
      null,
      undefined
    );
  }

  async createContact(data: IContactForm) {
    return await this.execute<ApiResponse<any>>(
      "post",
      "contact/create",
      data,
      null,
      undefined
    );
  }
}
