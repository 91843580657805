import api from "common/api";

export async function notificationsLoader() {
  try {
    const response = await api.account.notifications({ limit: 100, page: 1 });
    return response;
  } catch (err) {
    console.log(err);
  }
}
