import React from "react";
import Auth from "./Auth";
import { AuthContextProvider } from "../services/authContext";

const index = () => {
  return (
    <AuthContextProvider>
      <Auth />
    </AuthContextProvider>
  );
};

export default index;
