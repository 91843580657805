import React, { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const PrivateRoute = ({
  redirect = "/login",
  element,
}: {
  redirect?: string;
  element: React.ReactElement;
}) => {
  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth) navigate(redirect);
  }, []);

  return element;
};

export default PrivateRoute;
