import uz_UZ from "antd/lib/locale/uz_UZ";
import { Locale } from "antd/lib/locale";

export const uzLocale: Locale = {
  ...uz_UZ,
  DatePicker: {
    lang: {
      locale: "uz",
      placeholder: "Sanani tanlang",
      quarterPlaceholder: "Mavsumni tanlang",
      fieldQuarterFormat: "!fieldQuarterFormat",
      today: "Bugun",
      now: "Hozir",
      backToToday: "Bugunga qaytish",
      ok: "!OKEY",
      timeSelect: "!timeSelect",
      dateSelect: "!dateSelect",
      clear: "!clear",
      month: "!month",
      year: "!year",
      previousMonth: "!previousMonth",
      nextMonth: "!nextMonth",
      monthSelect: "!monthSelect",
      yearSelect: "!yearSelect",
      decadeSelect: "!decadeSelect",
      previousYear: "!previousYear",
      nextYear: "!nextYear",
      previousDecade: "!previousDecade",
      nextDecade: "!nextDecade",
      previousCentury: "!previousCentury",
      nextCentury: "!nextCentury",
    },
    timePickerLocale: {},
  },
  Pagination: {
    items_per_page: "/ sahifa",
  },
};
