import { IDocument, IPersonalInfo } from "common/types/account";
import { ApiResponse, IParamApi } from "../../types/common";
import { Api } from "../api";
import { IApplication } from "common/types/application";

export interface IAddApplication
  extends Omit<IPersonalInfo, "birth_date" | "valid_until"> {
  program_id: string;
  birth_date: string;
  valid_until: string;
}

export class ApplicationsApi extends Api {
  async getApplications() {
    return await this.execute<ApiResponse<any>>(
      "get",
      `application/get-all`,
      null,
      null,
      undefined
    );
  }

  async addApplication(data: IAddApplication, id?: string) {
    return await this.execute<ApiResponse<IApplication>>(
      id ? "put" : "post",
      id ? "application/update" : "application/create",
      { ...data, _id: id },
      null,
      undefined
    );
  }

  async getById(id: string) {
    return await this.execute<ApiResponse<IApplication>>(
      "get",
      `application/get-by-id/${id}`,
      null,
      null,
      undefined
    );
  }

  async getPayme(payload: {
    application_id: string;
    payment_type: "first_payment" | "second_payment";
    is_manual?: boolean;
  }) {
    return await this.execute<ApiResponse<any>>(
      "post",
      `application/pay`,
      payload,
      null,
      undefined
    );
  }

  async updateUserDoc(payload: { doc_id: string; value: string }) {
    return await this.execute<ApiResponse<any>>(
      "put",
      `user-docs`,
      payload,
      null,
      undefined
    );
  }

  async getProgramDocs(payload: { program_id: string }) {
    return await this.execute<ApiResponse<IDocument[]>>(
      "post",
      `user-docs/program`,
      payload,
      null,
      undefined
    );
  }

  async cancel(payload: { _id: string }) {
    return await this.execute<ApiResponse<IApplication>>(
      "post",
      `application/cancel`,
      payload,
      null,
      undefined
    );
  }

  async notifications(payload: { application_id: string }) {
    return await this.execute<any>(
      "post",
      "notification/application",
      payload,
      null,
      undefined
    );
  }
}
