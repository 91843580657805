import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Input, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PhoneNumberInput from "../../../../../components/form/phoneNumberInput/PhoneNumberInput";
import Button from "../../../../../components/ui/button/Button";
import useLoginContext, { FieldType } from "../services/loginContext";

const Login = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const {
    actions: { onFinish, onFinishFailed },
  } = useLoginContext();
  return (
    <Form
      name="login"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
    >
      <Form.Item<FieldType>
        label={t("auth.login.phone_number")}
        name="phone_number"
        rules={[{ required: true }]}
      >
        <PhoneNumberInput form={form} name="phone_number" onChange={() => {}} />
      </Form.Item>
      <Form.Item<FieldType>
        label={t("auth.login.password")}
        name="password"
        rules={[{ required: true }]}
      >
        <Input.Password size="large" />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          icon={<FontAwesomeIcon icon={faArrowRightToBracket} />}
          block
        >
          {t("auth.login.submit")}
        </Button>
      </Form.Item>
      <div>
        <Typography.Paragraph
          type="secondary"
          style={{ textAlign: "center", margin: 0 }}
        >
          {t("auth.login.has_not_account")}{" "}
          <Link to="/signup">{t("auth.login.registration")}</Link>
        </Typography.Paragraph>
      </div>
    </Form>
  );
};

export default Login;
