import { message, type FormProps } from "antd";
import React, { FC, createContext, useContext } from "react";
import api from "../../../../../common/api";
import useAuth from "../../../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useUser from "../../../../../hooks/useUser";

export type FieldType = {
  phone_number: string;
  password: string;
};

const Context = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const { setUser } = useUser();

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    try {
      const response = await api.account.login(values);
      if (response.data.token) {
        setAuth(response.data.token);
        setUser(response.data);
        navigate("/account/personal-info");
      }
    } catch (err) {
      console.log(err, "login");
    }
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.log("Failed:", errorInfo);
  };
  return {
    state: {},
    actions: { onFinish, onFinishFailed },
  };
};

const LoginContext = createContext<any>({ state: {}, actions: {} });

export const LoginContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
  );
};

export default function useLoginContext() {
  return useContext<ReturnType<typeof Context>>(LoginContext);
}
