import { IAchievements } from "common/types/achievements";
import { ApiResponse } from "../../types/common";
import { Api } from "../api";

class AchievementsApi extends Api {
  async getAchievements() {
    return await this.execute<ApiResponse<IAchievements[]>>(
      "get",
      "achievments/get-all",
      null,
      null,
      undefined
    );
  }
}

export default AchievementsApi;
