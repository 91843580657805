import { Api } from "./api";
import { AccountApi } from "./modules/account";
import AchievementsApi from "./modules/achievements";
import { ApplicationsApi } from "./modules/applications";
import { CommonApi } from "./modules/common";
import ContractApi from "./modules/contract";
import NewsApi from "./modules/news";
import { OtpApi } from "./modules/otp";
import ProgramsApi from "./modules/programs";
import UniversitiesApi from "./modules/universities";

const api = {
  common: new CommonApi(),
  account: new AccountApi(),
  news: new NewsApi(),
  achievements: new AchievementsApi(),
  universities: new UniversitiesApi(),
  programs: new ProgramsApi(),
  otp: new OtpApi(),
  applications: new ApplicationsApi(),
  contract: new ContractApi(),
};

export default api;
