import { ApiResponse } from "../../types/common";
import { INews, INewsApi, INewsFilter } from "../../types/news";
import { Api } from "../api";

class NewsApi extends Api {
  async getPaging(filter: INewsFilter) {
    return await this.execute<ApiResponse<INewsApi>>(
      "post",
      "news/paging",
      filter,
      null,
      undefined
    );
  }

  async getById(id: string) {
    return await this.execute<ApiResponse<INews>>(
      "get",
      `news/get-by-id/${id}`,
      null,
      null,
      undefined
    );
  }
}

export default NewsApi;
