import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import { uzLocale } from "./constants/configLocales/uzLocale";
import { enLocale } from "./constants/configLocales/enLocale";
import { ruLocale } from "./constants/configLocales/ruLocale";
import Auth from "./routes/auth/container";
import PrivateRoute from "./components/privateRoute/PrivateRoute";
import { personalInfoLoader } from "./routes/account/routes/personalInfo/services/personalInfoLoader";
import { documentsLoader } from "./routes/account/routes/documents/services/documentsLoader";
import { applicationsListLoader } from "./routes/account/routes/applications/list/services/applicationsListLoader";
import { notificationsLoader } from "routes/account/routes/notifications/services/notificationsLoader";

const Layout = React.lazy(() => import("./routes/layout/Layout"));
const Home = React.lazy(() => import("./routes/home/container"));
const NotFound = React.lazy(() => import("./routes/notfound/NotFound"));
const Programs = React.lazy(() => import("./routes/programs/list/container"));
const ProgramsInfo = React.lazy(
  () => import("./routes/programs/info/container")
);
const Universities = React.lazy(
  () => import("./routes/universities/list/container")
);
const UniversitiesInfo = React.lazy(
  () => import("./routes/universities/info/container")
);
const News = React.lazy(() => import("./routes/news/list/container"));
const NewsInfo = React.lazy(() => import("./routes/news/info/container"));
const Services = React.lazy(() => import("./routes/services/container"));
const Account = React.lazy(() => import("./routes/account/container"));
const PersonalInfo = React.lazy(
  () => import("./routes/account/routes/personalInfo/container")
);
const Documents = React.lazy(
  () => import("./routes/account/routes/documents/container")
);
const ApplicationsList = React.lazy(
  () => import("./routes/account/routes/applications/list/container")
);
const ApplicationsInfo = React.lazy(
  () => import("./routes/account/routes/applications/info/container")
);
const ApplicationsAdd = React.lazy(
  () => import("./routes/applicationsAdd/container")
);
const Notifications = React.lazy(
  () => import("./routes/account/routes/notifications/container")
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        // loader: homeLoader,
        element: <Home />,
      },
      {
        path: "programs",
        // loader: programsLoader,
        element: <Programs />,
      },
      {
        path: "programs/:id",
        // loader: programsLoader,
        element: <ProgramsInfo />,
      },
      {
        path: "universities",
        // loader: universitiesLoader,
        element: <Universities />,
      },
      {
        path: "universities/:id",
        // loader: universitiesLoader,
        element: <UniversitiesInfo />,
      },
      {
        path: "news",
        // loader: universitiesLoader,
        element: <News />,
      },
      {
        path: "news/:id",
        element: <NewsInfo />,
      },
      {
        path: "services",
        // loader: universitiesLoader,
        element: <Services />,
      },
      {
        path: "login",
        // loader: universitiesLoader,
        element: <Auth />,
      },
      {
        path: "signup",
        // loader: universitiesLoader,
        element: <Auth />,
      },
      {
        path: "account",
        element: <PrivateRoute element={<Account />} />,
        children: [
          {
            path: "personal-info",
            element: <PrivateRoute element={<PersonalInfo />} />,
            loader: personalInfoLoader,
          },
          {
            path: "documents",
            element: <PrivateRoute element={<Documents />} />,
            loader: documentsLoader,
          },
          {
            path: "applications",
            children: [
              {
                path: "list",
                element: <PrivateRoute element={<ApplicationsList />} />,
                loader: applicationsListLoader,
              },
              {
                path: "info/:id",
                element: <PrivateRoute element={<ApplicationsInfo />} />,
              },
            ],
          },
          {
            path: "notifications",
            element: <PrivateRoute element={<Notifications />} />,
            loader: notificationsLoader,
          },
          {
            path: "my-account",
            element: <p>my-account</p>,
          },
        ],
      },
      {
        path: "application/create/:program_id/:id?",
        element: <PrivateRoute element={<ApplicationsAdd />} />,
      },
    ],
  },
]);

function App() {
  const { i18n } = useTranslation();
  const getLocales = (lang: string) => {
    switch (lang) {
      case "uz":
        return uzLocale;
      case "en":
        return enLocale;
      case "ru":
        return ruLocale;
      default:
        return uzLocale;
    }
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#D32F2F",
          colorBgContainerDisabled: "rgb(246, 245, 247)",
        },
        components: {
          Button: {
            colorText: "#D32F2F",
            colorPrimary: "#D32F2F",
            algorithm: true,
          },
          Segmented: {
            itemSelectedBg: "#D32F2F",
            itemSelectedColor: "white",
          },
        },
      }}
      locale={getLocales(i18n.language)}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
