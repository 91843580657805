import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { Flex, Grid, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import LanguageSwitcher from "../../../components/ui/languageSwitcher/LanguageSwitcher";
import Login from "../components/login/container";
import Signup from "../components/signup/container";
import "../styles/Auth.css";

const { useBreakpoint } = Grid;
const contacts = [
  {
    icon: faPhone,
    label: "Bog'lanish:",
    value: "+998 (91) 004 44 46",
  },
  {
    icon: faEnvelope,
    label: "E-pochta:",
    value: "mail@xitoyda-talim.uz",
  },
  {
    icon: faLocationDot,
    label: "Manzil:",
    value:
      "Toshkent shahri, Mirobod tumani, Seoul Plaza biznes markazi 6-qavat",
  },
];

const Auth = () => {
  const location = useLocation();
  const screens = useBreakpoint();
  const { t } = useTranslation();
  const width = screens.xs ? "95%" : screens.sm ? "600px" : "95%";
  return (
    <div className="auth">
      <div
        style={{ maxWidth: width, margin: "auto" }}
        className="auth-container"
      >
        <Typography.Title
          style={{ color: "white", textAlign: "center" }}
          level={2}
        >
          {location.pathname === "/login" && t("auth.login.title")}
          {location.pathname === "/signup" && t("auth.signup.title")}
        </Typography.Title>

        <div
          className="auth-form"
          style={{ padding: screens.xs ? 30 : 50, marginBottom: 30 }}
        >
          <Flex justify="space-between" className="auth-form-header">
            <img src={logo} alt="xitoyda-talim-uz-logo" width={230} />
            <LanguageSwitcher />
          </Flex>
          {location.pathname === "/login" && <Login />}
          {location.pathname === "/signup" && <Signup />}
        </div>

        {/* <Space direction="vertical">
              {contacts?.map((item) => (
                <Flex className="auth-contacts" align="center" gap={20}>
                  <FontAwesomeIcon icon={item.icon} size="2xl" color="#fff" />
                  <div>
                    <Typography.Paragraph style={{ margin: 0, color: "#fff" }}>
                      {item.label}
                    </Typography.Paragraph>
                    <Typography.Title
                      level={5}
                      style={{ margin: 0, color: "#fff" }}
                    >
                      {item.value}
                    </Typography.Title>
                  </div>
                </Flex>
              ))}
            </Space> */}
      </div>
    </div>
  );
};

export default Auth;
